










































import Vue from 'vue';
export default Vue.extend({
  props: {
    name: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      required: true,
    },
    difficulty: {
      type: Number,
      required: true,
    },
    visuals: {
      type: Number,
      required: true,
    },
    fun: {
      type: Number,
      required: true,
    },
    mechanics: {
      type: String,
      required: true,
    },
    franchises: {
      type: String,
      required: true,
    },
    comment: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
    };
  },
  methods: {
    minNumberIsMaxNumber(number1: number, number2: number) {
      return number1 < number2 ? number1 : number2;
    },
    maxNumberIsMinNumber(number1: number, number2: number) {
      return number1 > number2 ? number1 : number2;
    },

    getDotColorCss(score: number, index: number) {
      // const lowest = score === index ? score : index;
      const baseIndex = index - 3;
      if (baseIndex === 0) {
        return 'bg-white';
      }
      const minus = baseIndex > 0 && score >= baseIndex;
      const plus = baseIndex < 0 && score <= baseIndex;
      const size = Math.abs(baseIndex) + 1;
      const keephereforpurgecsstokeepcolorsincssfilebecauseitsconcatenatedstring = 'bg-green-100 bg-green-300 bg-green-500 bg-red-100 bg-red-300 bg-red-500';
      if (plus) {
        return `bg-green${this.maxNumberIsMinNumber(score, baseIndex) * 2 - 1}00 h-${size}`;
      }

      if (minus) {
        return `bg-red-${this.minNumberIsMaxNumber(score, baseIndex) * 2 + 1}00 h-${size}`;
      }
      return 'opacity-50 bg-gray-500';
    },
    difficultyDot(difficulty: number, index: number): string {
      return this.getDotColorCss(difficulty, index);
    },
    fiveDotRating(score: number, index: number): string {
      const scoreBase = score - 1;
      if (scoreBase > 0 && index === scoreBase) {
        return `bg-yellow-400 h-3`;
      }

      if (scoreBase > 0 && index <= scoreBase) {
        return `bg-yellow-400 h-2`;
      }
      return 'opacity-50 bg-gray-500 h-2';
    }
  },
});
